// import { Trans } from 'react-i18next'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button } from '@legacy/@core/components/share/ANTD/Button'
import { Form } from '@legacy/@core/components/share/ANTD/Form'
import { Col, Row } from '@legacy/@core/components/share/ANTD/Grid'
import { Input } from '@legacy/@core/components/share/ANTD/Input'
import { AppLink } from '@legacy/@core/components/share/AppLink'
import Footer from '@legacy/@core/layout/Footer'
import { SocialStyle } from '@legacy/@core/style/styled/antd/Button.style'
import { LoginGlobalStyle, LoginStyle } from '@legacy/@core/style/styled/LoginStyle.style'
import { LINK_URL_CHAT_WITH_SUPPORT, LINK_URL_FORGOT_PASSWORD, LINK_URL_ROOT } from '@legacy/@core/utilities/LinkURL'
import { TRACKING_ID_LOGIN_LOGIN } from '@legacy/@core/utilities/TrackingIDConstant'
import Image from 'next/image'
import React, { FC, ReactElement } from 'react'
import { RESOURCE_IMAGE_FREEC_LOGO } from 'utilities/Constant'

const LoginComponent: FC<{
  verifyData?: { email: string; user_id: number }
  nonFieldError?: string | ReactElement
  isVerify?: boolean
  loginIn?: boolean
  message?: string
  onFinish?: (arr1) => void
  onSendVerify?: (arr1, arr2) => void
}> = (props) => {
  const { verifyData, nonFieldError, isVerify, loginIn, message, onFinish, onSendVerify } = props

  return (
    <>
      <style jsx>{LoginStyle}</style>
      <style jsx global>
        {LoginGlobalStyle}
      </style>
      <style jsx global>
        {SocialStyle}
      </style>
      <div className="login-page">
        <div className="d-flex align-items-center justify-content-center flex-column login-form-wrapper">
          <Row justify="center" className="w-100">
            <Col xs={20} md={16} lg={12} xl={8}>
              <div className="logo">
                <AppLink href={LINK_URL_ROOT}>
                  <a className="header-logo-img">
                    <Image src={RESOURCE_IMAGE_FREEC_LOGO} alt="freec-platform" width={150} height={42} />
                  </a>
                </AppLink>
              </div>
              <div className="login-form">
                <Form name="login_form" layout="vertical" onFinish={onFinish}>
                  <div>
                    <p className="title mb-2">Log In</p>
                    {message && (
                      <Row justify="center">
                        <Col>
                          <div className="message-notice">
                            <ExclamationCircleFilled style={{ color: '#1890FF' }} />
                            <span className="message-text mr-2">{message}</span>
                            {isVerify && (
                              <Button className="btn-resend_email" onClick={() => onSendVerify(verifyData.email, verifyData.user_id)}>
                                Resend Email
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                  <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!'
                      },
                      {
                        max: 250,
                        message: `Only contain maximum 250 characters`
                      },
                      {
                        type: 'email',
                        message: 'Invalid email format'
                      }
                    ]}>
                    <Input placeholder="email@example.com" size="large" maxLength={250} id="login_form_email" />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!'
                      },
                      {
                        min: 6,
                        message: 'This field must be between 6 and 20 characters'
                      }
                    ]}>
                    <Input.Password
                      size="large"
                      placeholder="Your password must contain at least 6 characters"
                      id="login_form_password"
                      maxLength={20}
                    />
                  </Form.Item>
                  <div className="error-text">{nonFieldError && <div>{nonFieldError}</div>}</div>
                  <AppLink href={LINK_URL_FORGOT_PASSWORD}>
                    <a className="link-forgot-pw" id="link_forgot_password">
                      Forgot Password?
                    </a>
                  </AppLink>
                  <Form.Item>
                    <Button
                      id={TRACKING_ID_LOGIN_LOGIN}
                      loading={loginIn}
                      type="primary"
                      className="btn-submit w-100"
                      htmlType="submit"
                      size="large">
                      Log In
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
          <Row justify="center" className="w-100 mt-3">
            <Col xs={20} md={16} lg={12} xl={8}>
              <div className="d-flex align-items-center justify-content-space-between">
                <p className="text-bottom">
                  {`Do you need support? `}
                  <AppLink href={LINK_URL_CHAT_WITH_SUPPORT}>
                    <a className="link-bottom" id="link_create_account">
                      Contact us
                    </a>
                  </AppLink>
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default LoginComponent
